.App {
  min-height: calc(100vh - 60px);
  font-family: Futura, sans-serif;
  overflow-x: hidden;
}

.App-header {
  padding-top: 100px;
  padding-bottom: 10px;
  background-color: #FECADA;
  color: black;
}

.App-header-content, .App-main {
  width: 80%;
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
}

.App-main {
  padding-bottom: 200px;
  height: calc(100vh - 330px);
}

.App-footer {
  width: 100%;
  text-align: center;
  font-family: 'Courier New', Courier, monospace;
  font-size: .7em;
  margin-bottom: 20px;
  position: sticky;
  bottom: 0;
}

.App-footer p {
  margin: 4px;
}

.button {
  background-color: #FECADA;
  color: black;

  width: 100%;
  padding: 10px 5px;
  
  border: 0;
  border-radius: 5px;

  cursor: pointer;

  /* letter-spacing: 0.4px; */
  font-family: Futura, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 16px;

  transition: all .1s ease-in-out;
}

.button:not("disabled"):hover, .button:focus {
  transform: translateY(1px);
}
.button:active {
  opacity: 0.8;
}

.button:disabled {
  opacity: 0.4;
}

.text-input {
  -webkit-appearance: none;
  display: block;
  background: #fff;

  width: 100%;
  padding: 10px 12px;

  border-radius: 5px;
  border: 1px solid #ddd;

  line-height: normal;
  /* letter-spacing: 0.4px; */
  font-family: Futura, sans-serif;
  font-size: 16px;

  box-sizing: border-box;
  transition: border-color .1s ease-in-out;
  outline: 0;
}

.separate-top {
  margin-top: 10px;
}

.turnario-new-app .button {
  box-shadow: 0 4px #fc709b;
  border-radius: 10px;
  background-color: #FECADA;
  color: black;
  border-width: 2px;
  border-color: transparent;
}

.turnario-new-app .button:active {
  opacity: 1;
  transform: translateY(4px);
  box-shadow: none;
}

.turnario-new-app .button:disabled {
  opacity: 0.6;
  background-color: #fc709b;
}

/* logo colors: #FECADA #E3BCFF #C1E5E5 #FFE4A6 #D0FECA */
.logo-img {
  width: 100%;
  max-width: 300px;
}

/*
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/